import React, { PropsWithChildren, useEffect, useState } from "react";
import SideOver from "./SideOver";

type context = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setAfterLeave: React.Dispatch<React.SetStateAction<void>>;
};

const SideOverContext = React.createContext<context | null>(null);

export default SideOverContext;

export function DashboardSideOver({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode>();
  const [afterLeave, setAfterLeave] = useState<void>();

  useEffect(() => {
    setOpen(!!content);
  }, [content]);

  return (
    <SideOverContext.Provider
      value={{
        open,
        setOpen,
        setContent,
        setAfterLeave,
      }}
    >
      <SideOver open={open} setOpen={setOpen} afterLeave={afterLeave}>
        {content}
      </SideOver>
      {children}
    </SideOverContext.Provider>
  );
}

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { z } from "zod";
import UserSession from "../components/UserSession";
import { DashboardSideOver } from "../components/layout/SideOverContext";
import zodErrorMap from "../config/zodErrorMap";
import "../styles/globals.css";
import { trpc } from "../utils/trcp";
config.autoAddCss = false;

z.setErrorMap(zodErrorMap);

function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const queryClient = new QueryClient();

  return (
    <SessionProvider
      session={session}
      refetchInterval={5 * 60}
      // refetchOnWindowFocus={false}
    >
      <UserSession />
      <QueryClientProvider client={queryClient}>
        <ToastContainer position="top-right" />
        <DashboardSideOver>
          <Component {...pageProps} />
        </DashboardSideOver>
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default trpc.withTRPC(App);

import { signOut, useSession } from "next-auth/react";
import React, { useEffect } from "react";

// This component is used to logout the user when the session
// returns an error (cant retrieve user from database). It is used in _app.tsx

function UserSession() {
  const { data } = useSession();
  useEffect(() => {
    if (data?.error) signOut({ callbackUrl: "/" });
  }, [data]);

  return null;
}

export default UserSession;

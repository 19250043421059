import { z } from "zod";

let messages = {
  required: "Este campo es requerido.",
};

const zodErrorMap: z.ZodErrorMap = (error, ctx) => {
  /*
  This is where you override the various error codes
  */
  switch (error.code) {
    case z.ZodIssueCode.too_small:
      if (error.minimum === 1) return { message: messages.required };
      break;
    case z.ZodIssueCode.invalid_type:
      if (error.received === "undefined" || error.received === "null")
        return { message: messages.required };
      break;
    case z.ZodIssueCode.invalid_string:
      if (error.validation === "email") return { message: "Email inválido." };
      break;
  }

  // fall back to default message!
  return { message: ctx.defaultError };
};

export default zodErrorMap;

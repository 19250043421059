/* This example requires Tailwind CSS v2.0+ */
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";

type props = {
  title?: string;
  wide?: boolean;
  afterLeave?: void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  children: React.ReactNode;
};

/**
 * UN COMPONENTE PARA SER USADO SIN NAVEGACIÓN
 */

const SideOver = ({
  children,
  title,
  wide = false,
  afterLeave,
  open = true,
  setOpen,
}: props) => {
  return (
    <Transition.Root show={open} as={Fragment} appear={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-hidden"
        onClose={() => setOpen && setOpen(false)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              afterLeave={() => afterLeave}
            >
              <div
                className={classNames(
                  "pointer-events-auto w-screen",
                  wide ? "max-w-2xl" : "max-w-xl"
                )}
              >
                <div className="flex h-full flex-col bg-white pt-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      {title && (
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {title}
                        </Dialog.Title>
                      )}
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => setOpen && setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <FontAwesomeIcon
                            icon={faX}
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative my-6 grow px-4 overflow-y-auto sm:px-6 h-full">
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SideOver;
